import { applyMiddleware, combineReducers, createStore } from "redux";
import { i18nState } from "redux-i18n";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

import rootSaga from "./sagas";
import entities from "./entities";

const actions: any = {};
const selectors: any = {};
const reducers: any = {
  i18nState,
};

Object.keys(entities).forEach((key) => {
  selectors[key] = entities[key].selectors;
  actions[key] = entities[key].actions;
  reducers[key] = entities[key].reducer;
});

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware);

const combinedReducers = combineReducers(reducers);

export const store = createStore(
  combinedReducers,
  composeWithDevTools(enhancer),
);

sagaMiddleware.run(rootSaga);
