import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";

import "./Timer.scss";
import { Answer } from "../../utils/DataTypes.interface";
import abs from "../../components/MouseTrail/utils/abs";

const TIMER = 30000;

interface TimerProps {
  start: boolean;
  stop: (timer: number) => void;
  answers: Answer[];
}

const Timer: React.FC<TimerProps> = ({ start, stop, answers }) => {
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const endTime = useRef<number>(Number(new Date()) + TIMER);
  const [timerInS, setTimerS] = useState<number>(TIMER / 1000);
  const [timerInMs, setTimerMs] = useState<number>(0);

  const timeStyle = useMemo(
    () => ({
      color: timerInS <= 5 ? "#FF4E4E" : "black",
    }),
    [timerInS],
  );

  const handleTimer = useCallback(() => {
    const timer = (endTime.current - Date.now()) / 1000;
    const sec = Math.floor(timer);
    const ms = Math.floor(abs(timer - sec) * 100);

    if (sec >= 0) {
      setTimerS(sec);
    }

    setTimerMs(ms);
  }, []);

  useEffect(() => {
    const remainingAnswers = answers.some(
      (item: Answer) => !item.sliced && item.isTrue,
    );
    if (start && (timerInS <= 0 || !remainingAnswers)) {
      stop(timerInS);
      if (timerRef.current) clearInterval(timerRef.current);
    }
  }, [stop, timerInS, timerInMs]);

  useEffect(() => {
    if (start) {
      if (timerRef.current) clearInterval(timerRef.current);
      handleTimer();
      timerRef.current = setInterval(handleTimer, 100);
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [start]);

  return (
    <div className="timer" style={timeStyle}>
      {timerInS}:<span>{timerInMs}</span>
    </div>
  );
};

export default Timer;
