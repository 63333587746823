import React from "react";

// Hooks
import { IonIcon } from "@ionic/react";

// Style
import IconTypes from "../../types/IconTypes";
import "./Icon.scss";

const myIcons = Object.values(IconTypes);

interface IonIconProps {
  id?: string;
  ariaLabel?: string;
  color?: string;
  flipRtl?: boolean;
  icon?: string;
  ios?: string;
  lazy?: boolean;
  md?: string;
  mode?: "ios" | "md";
  name?: string;
  size?: string;
  src?: string;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  onMouseDown?: any;
  onTouchStart?: any;
  onTouchEnd?: any;
}

// Renderer
const Icon: React.FC<IonIconProps> = React.memo((props: any) => {
  let myProps = { ...props };
  const { icon, disabled } = myProps;

  if (!!disabled) {
    myProps.onClick = undefined;
    myProps.className = (myProps.className || "") + " disabled";
  }

  if (myIcons.includes(icon)) {
    return (
      <IonIcon
        disabled={undefined}
        {...myProps}
        icon={undefined}
        src={`/assets/icons/${icon}.svg`}
      />
    );
  }

  return <IonIcon {...myProps} disabled={undefined} />;
});

export const icons = IconTypes;
export default Icon;
