const MAX_CUT = 50;

function getClippedRegion(
  size: { width: number; height: number },
  x: number,
  y: number,
  type: "horizontal" | "vertical",
  isOtherPart: boolean,
) {
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
  if (!ctx) return null;

  const { width, height } = size;
  canvas.width = width;
  canvas.height = height;

  const w20 = width * 0.05;
  const h20 = height * 0.05;

  if (type === "horizontal") {
    const initY = isOtherPart ? height : 0;
    if (y < h20 || y > height - h20) {
      y = height / 2;
    }
    ctx.moveTo(0, initY);
    ctx.beginPath();
    ctx.lineTo(width, initY);
    ctx.lineTo(width, y);
    for (let i = MAX_CUT; i >= 0; i -= 2) {
      ctx.lineTo((i * width) / MAX_CUT, i <= 1 ? y : y + 1);
      ctx.lineTo(((i - 1) * width) / MAX_CUT, i <= 1 ? y : y - 1);
    }
    ctx.lineTo(0, y);
    ctx.lineTo(0, initY);
    ctx.closePath();
  } else {
    const initX = isOtherPart ? width : 0;
    if (x < w20 || x > width - w20) {
      x = width / 2;
    }
    ctx.moveTo(initX, 0);
    ctx.beginPath();
    ctx.lineTo(initX, height);
    ctx.lineTo(x, height);
    for (let i = MAX_CUT; i >= 0; i -= 2) {
      ctx.lineTo(i <= 1 ? x : x + 1, (i * height) / MAX_CUT);
      ctx.lineTo(i <= 1 ? x : x - 1, ((i - 1) * height) / MAX_CUT);
    }

    ctx.lineTo((3 * x) / 4, (3 * height) / 4);
    ctx.lineTo(x, (2 * height) / 4);
    ctx.lineTo((3 * x) / 4, height / 4);
    ctx.lineTo(x, 0);
    ctx.lineTo(initX, 0);
    ctx.closePath();
  }
  ctx.closePath();
  ctx.clip();

  return canvas;
}

export default getClippedRegion;
