import { FeedBackType } from "./types/FeedBack.types";
import "../../assets/fonts/SF-Pro-Display-Black.otf";

class FeedBack {
  // Data Variables
  public x: number;
  public y: number;
  public id: string;
  public text: string;
  public color: string;
  public fontSize: string;
  public size?: {
    height: number;
    width: number;
  };

  // // Define class constructor
  constructor(message: FeedBackType) {
    // Manage initial position
    this.y = message.y;
    this.x = message.x;
    this.id = message.id;
    this.text = message.text;
    this.color = message.color;
    this.fontSize = message.fontSize;
  }

  // // Draw the message
  draw(context: CanvasRenderingContext2D, onDelete: (id: string) => void) {
    this.setTextContext(context, this.x, this.y);

    const metrics = context.measureText(this.text);
    const fontHeight =
      metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    this.size = {
      width: metrics.width,
      height: fontHeight,
    };

    setTimeout(() => onDelete(this.id), 400);
  }

  // Default settings
  setTextContext(
    context: CanvasRenderingContext2D | null,
    x: number,
    y: number,
  ) {
    if (!context) return;
    context.strokeStyle = "white";
    context.lineWidth = 5;
    context.fillStyle = this.color;
    context.font = `${this.fontSize}px SF-Pro-Display-Black`;
    context.strokeText(this.text, this.x, this.y);
    context.fillText(this.text, this.x, this.y);
  }
}

export default FeedBack;
