import React from "react";
import { User } from "../../utils/DataTypes.interface";
import { useLocalize } from "../../redux/translation/localize";

import "./UserCard.scss";
import { defaultProfile } from "../../utils/defaultProfile";

interface Props {
  user: User;
  index: number;
}

const UserCard: React.FC<Props> = ({ user, index }) => {
  const t = useLocalize();

  return (
    <div className="userCard">
      <div className="userRank">{index + 1}</div>
      <img
        alt="user"
        src={user.picture ? user.picture : defaultProfile}
        className="rankingPP"
      />
      <div className="rankingName">{user.name}</div>
      <div className="rankingPoints">
        {t("points_short", { number: user.points.toString() })}
      </div>
    </div>
  );
};

export default UserCard;
