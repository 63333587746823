import { all, put, takeLatest, select, call, delay } from "redux-saga/effects";
import {
  actions as TranslationActions,
  selectors as TranslationSelectors,
} from "./redux";

import { REACT_APP_STATIC_ASSETS } from "../../utils/env";

import axios from "axios";
import Response from "./Response";
export default class TranslationSagas {
  static *loadLanguages(assetName, forceLang = undefined, retry = 0) {
    if (retry > 10) {
      return Response.fail(`Too many atemps`);
    }

    const userLang = yield select(TranslationSelectors.lang);
    const lang = forceLang || userLang;
    const path =
      REACT_APP_STATIC_ASSETS +
      assetName +
      "/v1/translation/" +
      lang +
      ".json?i=" +
      Math.random();

    let result = null;
    try {
      result = yield axios.get(path, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": window.location.origin,
        },
      });
    } catch (err) {
      console.warn(err);
      return Response.fail(`Axios Problem ` + err);
    }
    const status = result && result.status;

    switch (status) {
      case 200:
        try {
          const parsedResult =
            typeof result.data === "string"
              ? JSON.parse(result.data)
              : result.data;
          return Response.success(parsedResult);
        } catch (e) {
          console.warn(`can't parse json ` + e);
          return Response.fail(`can't parse json ` + e);
        }
        break;
      case 404:
        return Response.fail("Static asset not found at " + path);
        break;
      default:
        yield delay(1000);
        return yield call(
          TranslationSagas.localizedStaticAsset,
          assetName,
          forceLang,
          retry + 1,
        );
        break;
    }
  }

  static *setLanguage({ payload }) {
    let { language, assetName } = payload;
    const translations = yield select((state) => state.i18nState.translations);

    if (!translations[language]) {
      const result = yield call(
        TranslationSagas.loadLanguages,
        assetName,
        language,
      );
      const translation = result.data;

      yield put({
        type: "REDUX_I18N_SET_TRANSLATIONS",
        translations: { ...translations, [language]: translation },
      });
      yield put({ type: "REDUX_I18N_SET_LANGUAGE", lang: language });
      yield put({ type: "REDUX_I18N_SET_FORCE_REFRESH", force: true });
      yield put(TranslationActions.loadLanguagesSuccess());
    } else {
      yield put({ type: "REDUX_I18N_SET_LANGUAGE", lang: language });
      yield put({ type: "REDUX_I18N_SET_FORCE_REFRESH", force: true });
    }
    return null;
  }

  static *loop() {
    yield all([
      yield takeLatest(
        TranslationActions.requestLoadLanguages.getType(),
        TranslationSagas.loadLanguages,
      ),
      yield takeLatest(
        TranslationActions.setLanguage.getType(),
        TranslationSagas.setLanguage,
      ),
    ]);
  }
}
