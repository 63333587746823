import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({});

export const actions = {
  requestLoadLanguages: createAction("request load languages", (lang) => ({
    lang,
  })),
  setLanguage: createAction(
    "set language",
    (language, assetName = "shiseido") => ({
      language,
      assetName,
    }),
  ),
  loadLanguagesSuccess: createAction("loadLanguagesSuccess"),
};

export const reducer = createReducer({}, initialState);

export const selectors = {
  lang: (state) => state.i18nState.lang,
};
