import { all, takeLatest } from "redux-saga/effects";
import { actions as AppActions } from "./redux";

export default class AppSagas {
  static *startApp({ payload }) {}

  static *loop() {
    yield all([takeLatest(AppActions.startApp.getType(), AppSagas.startApp)]);
  }
}
