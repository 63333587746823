const icons = {
  IMAGE_WITHOUT_BORDER: "Image_without_border",
  ABOUT: "about",
  ADD_COMMENT: "add-comment",
  ADD_CONTACT: "add-contact",
  ADD_PHOTO: "add-photo",
  ALIGN_CENTER: "align-center",
  ALIGN_LEFT: "align-left",
  ALIGN_RIGHT: "align-right",
  ARCHIVE: "archive",
  ARROW_DOWN: "arrow-down",
  ARROW_LEFT: "arrow-left",
  ARROW_RIGHT: "arrow-right",
  ARROW_UP: "arrow-up",
  ASSISTANCE: "assistance",
  ATTACHMENT: "attachment",
  BACK_ARROW: "back-arrow",
  BATTLE: "battle",
  BIG_LEFT_OPEN: "big-left-open",
  BIG_RIGHT_OPEN: "big-right-open",
  BLASON: "blason",
  BOOKMARK_FILLED: "bookmark-filled",
  BOOKMARK: "bookmark",
  BOUTIQUE: "boutique",
  BULLET_LIST: "bullet-list",
  CACHE: "cache",
  CAMERA: "camera",
  CHAT: "chat",
  CHECKLIST: "checklist",
  CHEVRON_LEFT: "chevron-left",
  CHEVRON_RIGHT: "chevron-right",
  CIRCLE_FILLED: "circle-filled",
  CLOSE_CIRCLED: "close-circled",
  CLOSE: "close",
  CODE_BLOC: "code-bloc",
  COAT: "coat",
  CODE: "code",
  COMMENT: "comment",
  COMPLIANT: "compliant",
  CONTACT_LIST: "contact-list",
  COPY: "copy",
  CROP: "crop",
  DATA: "data",
  DELETE_MULTIPLE: "delete-multiple",
  DELETE: "delete",
  DOCUMENT: "document",
  DOWN_OPEN: "down-open",
  DOWNLOAD: "download",
  EDIT_MESSAGE: "edit-message",
  EDIT: "edit",
  EMAIL: "email",
  EYE_CLOSED: "eye-closed",
  EYE: "eye",
  FACEBOOK: "facebook",
  FILE: "file",
  FILTER: "filter",
  FLASH_OFF: "flash-off",
  FLASH: "flash",
  FLOOR_COACHING: "floor-coaching",
  FONT_BOLD: "font-bold",
  FONT_BULLET_LIST: "font-bullet-list",
  FONT_ITALIC: "font-italic",
  FONT_NUMBERED_LIST: "font-numbered-list",
  FONT_UNDERLINE: "font-underline",
  GALLERY: "gallery",
  GIF: "gif",
  HEART_FILLED: "heart-filled",
  HEART: "heart",
  HOME: "home",
  IMAGE: "image",
  INFO: "info",
  INSTAGRAM: "instagram",
  JOB: "job",
  KELLOGGS: "kelloggs",
  LANGUE: "langue",
  LEARN: "learn",
  LEFT_OPEN: "left-open",
  LIBRARY: "library",
  LINK: "link",
  LINKEDIN: "linkedin",
  LOCALISATION_FILLED: "localisation-filled",
  LOCALISATION: "localisation",
  LOCATION: "location",
  LOCK: "lock",
  LOGOUT: "logout",
  MAP: "map",
  MENU: "menu",
  MICROPHONE: "microphone",
  MINUS: "minus",
  MORE_EXTRUDE: "more-extrude",
  MORE_HORIZONTAL: "more-horizontal",
  MORE: "more",
  MUTE: "mute",
  MUTED: "muted",
  NOTIFICATION: "notification",
  OK_CIRCLED: "ok-circled",
  OK: "ok",
  OPTION: "option",
  PARAMETERS: "parameters",
  PAUSE: "pause",
  PDF: "pdf",
  PENCIL_GLOW: "pencil-glow",
  PENCIL: "pencil",
  PIN: "pin",
  PINNED: "pinned",
  PLANNING: "planning",
  PLAY: "play",
  PLUS_CIRCLED_FILLED: "plus-circled-filled",
  PLUS_CIRCLED: "plus-circled",
  PLUS: "plus",
  POLL: "poll",
  POSITION_FILLED: "position-filled",
  POSITION: "position",
  PPT: "ppt",
  PRIVACY: "privacy",
  PROFILE_STATUS: "profile-status",
  PROFILE: "profile",
  PUBLISH: "publish",
  QUESTION: "question",
  QUOTATION: "quotation",
  QUOTE_CONTENT: "quote-content",
  READ: "read",
  REPLAY: "replay",
  REPLY: "reply",
  REPORT: "report",
  RESIZE_DOWN: "resize-down",
  RESIZE_FULL: "resize-full",
  REWARD: "reward",
  RIGHT_OPEN: "right-open",
  ROTATE: "rotate",
  SCROLL_TOP: "scroll-top",
  SEARCH: "search",
  SECTION: "section",
  SEND: "send",
  SENIORITY: "seniority",
  SEPARATOR: "separator",
  SHARE: "share",
  STAR_FILLED: "star-filled",
  STAR: "star",
  STICKERS: "stickers",
  SUBTITLE: "subtitle",
  TABLE: "table",
  TEXT: "text",
  TERMS_OF_USE: "terms-of-use",
  TIME: "time",
  TIMER: "timer",
  TITLE: "title",
  TOOLS: "tools",
  TRANSFER: "transfer",
  TRASH: "trash",
  TRIANGLE_DOWN: "triangle-down",
  TURN_CAMERA: "turn-camera",
  TWITTER: "twitter",
  UNARCHIVE: "unarchive",
  UNLOCK: "unlock",
  UNMUTED: "unmuted",
  UNPIN: "unpin",
  UP_OPEN: "up-open",
  VIDEO: "video",
  XLS: "xls",
  YEARBOOK: "yearbook",
  LIKE: "like",
  THUMBS_UP: "thumbs-up",
  REACTION_THUMBSUP: "reaction-tmp-thumbsup",
  REACTION_HEART: "reaction-tmp-heart",
  REACTION_SAD: "reaction-tmp-sad",
  REACTION_WORRY: "reaction-tmp-worry",
  REACTION_CLAP: "reaction-tmp-clap",
  GIPHY: "giphy-logo",
};

export default icons;
