import { useIonRouter } from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalize } from "../../redux/translation/localize";
import { useGameConfig, useGame } from "../../redux/game/hooks";
import Loader from "../../atoms/Loader/Loader";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Backgrounds from "../../components/Backgrounds/Backgrounds";
import backgroundImg from "../../assets/img/ninja_game_background.png";

import "./Menu.scss";
import imageCache from "../../utils/imageCache";
import textCache from "../../utils/textCache";
import { addGnl } from "../../utils/stringUtils";
import "../../assets/fonts/SF-Pro-Display-Black.otf";

const TEXTLENGTH = 8;

const Menu = () => {
  const { push } = useIonRouter();
  const { getConfig, configExists } = useGameConfig();
  const { designConfig, questionIndex, questions } = useGame();
  const t = useLocalize();
  const fontStyle = useMemo(
    () => ({
      color: designConfig?.background ? "black" : "black",
    }),
    [designConfig?.background],
  );
  const backgroundStyle = useMemo(
    () => ({
      backgroundColor: designConfig?.background ? "" : "white",
    }),
    [designConfig?.background],
  );

  const [answersLoaded, setAnswersLoaded] = useState<boolean>(false);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  useEffect(() => {
    loadAnswers();
  }, [configExists]);

  ///load answers
  const loadImage = async (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ img, url });
      };
      img.src = url;
    });
  };

  const loadText = (msg: string) => ({
    msg,
    text: msg.length > TEXTLENGTH ? addGnl(msg, TEXTLENGTH) : [msg],
  });

  const loadAnswers = async () => {
    const loadingQueueIMG: any[] = [];
    const loadingQueuetxt: any[] = [];
    questions.forEach((element: any) => {
      element.answers.forEach((el: any) => {
        if (el.type === "image") loadingQueueIMG.push(loadImage(el.url));
        if (el.type === "text") loadingQueuetxt.push(loadText(el.answer));
      });
    });
    const result = await Promise.all(loadingQueueIMG);
    result.forEach((r) => {
      imageCache.set(r.url, r.img);
    });
    loadingQueuetxt.forEach((r) => {
      textCache.set(r.msg, r.text);
    });
    setAnswersLoaded(true);
  };
  /////////

  const startGame = useCallback(() => {
    const elem = document.getElementById("Menu");
    if (!elem) return;
    elem.style.opacity = "0";
    setTimeout(() => push(`/question/${questionIndex + 1}`), 1000);
  }, [push, questionIndex]);

  const handleInstruction = useCallback(() => {
    const elem = document.getElementById("Menu");
    if (!elem) return;
    elem.style.opacity = "0";
    setTimeout(() => push("/instructions"), 1000);
  }, [push]);

  return (
    <div id="Menu" className="page" style={backgroundStyle}>
      {!!designConfig?.background && (
        <Backgrounds
          blur
          color="transparent"
          instructions
          url={designConfig.background}
        />
      )}
      <Header />
      {(!configExists || !answersLoaded) && <Loader />}
      {!!configExists && answersLoaded && (
        <div className="menuBody">
          <div className="menu">
            <div className="title">{t("ninja_game_name")}</div>
            <div className="hint" style={fontStyle}>
              {t("ninja_game_hint")}
            </div>
            <div className="description" style={fontStyle}>
              <div>{t("ninja_game_instruction")}</div>
              <br />
              <div>{t("ninja_game_disclamer")}</div>
            </div>
          </div>
          <div className="buttonBox">
            <Button label={t("how_to_play")} onClick={handleInstruction} />
            <Button label={t("lets_play")} onClick={startGame} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
