import React, { useMemo } from "react";

import "./Backgrounds.scss";

interface BackgroundsProps {
  url: string;
  color: string;
  blur: boolean;
  gradient?: boolean;
  instructions?: boolean;
}

const Backgrounds: React.FC<BackgroundsProps> = ({
  url,
  color,
  blur,
  gradient,
  instructions,
}) => {
  const backgroundStyle = useMemo(
    () => ({
      background: gradient ? `radial-gradient(white, ${color})` : color,
      opacity: instructions ? "0.5" : "0.2",
    }),
    [color, gradient, instructions],
  );
  const imgStyle = useMemo(
    () => ({
      filter: instructions ? "blur(3px)" : "blur(0px)",
      webkitFilter: instructions ? "blur(3px)" : "blur(0px)",
    }),
    [instructions],
  );

  return (
    <div className="background">
      {blur && <div className="backgroundBlur" style={backgroundStyle} />}
      <img
        src={url}
        alt="background"
        className="backgroundImg"
        style={imgStyle}
      />
    </div>
  );
};

export default Backgrounds;
