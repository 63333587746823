import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

// Pages
import Game from "../pages/Game/Game";
import Menu from "../pages/Menu/Menu";
import EndPage from "../pages/EndPage/EndPage";
import Question from "../pages/Question/Question";
import Instructions from "../pages/Instructions/Instructions";

const Router: React.FC = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/">
          <Menu />
        </Route>
        <Route exact path="/instructions">
          <Instructions />
        </Route>
        <Route exact path="/question/:questionId">
          <Question />
        </Route>
        <Route exact path="/game/:questionId">
          <Game />
        </Route>
        <Route exact path="/finish">
          <EndPage />
        </Route>
        <Redirect to="/" />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Router;
