import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  config: null,
  currentScore: 0,
  results: null,
  questionIndex: 0,
  comboCounter: 1,
});

export const actions = {
  requestGetConfig: createAction("getting game config"),
  requestSetConfig: createAction("setting game config", (config) => ({
    config,
  })),
  requestSetQuestionIndex: createAction(
    "setting question index",
    (questionIndex) => ({
      questionIndex,
    }),
  ),

  // Actions with Parent
  requestAddScore: createAction("adding new score"),

  requestGetResults: createAction("getting results from the game"),
  requestSetResults: createAction(
    "setting results from the game",
    (results) => ({ results }),
  ),
  requestSetCurrentScore: createAction(
    "setting current score",
    (currentScore) => ({ currentScore }),
  ),
  requestCompleteGame: createAction("completing the game"),
  requestCloseGame: createAction("closing the game"),
  requestSetComboCounter: createAction("setting combo counter", (counter) => ({
    counter,
  })),
};

export const reducer = createReducer(
  {
    [actions.requestSetConfig]: (state, { config }) => state.merge({ config }),
    [actions.requestSetQuestionIndex]: (state, { questionIndex }) =>
      state.merge({ questionIndex }),
    [actions.requestSetResults]: (state, { results }) =>
      state.merge({ results }),
    [actions.requestSetCurrentScore]: (state, { currentScore }) =>
      state.merge({ currentScore }),
    [actions.requestSetComboCounter]: (state, { counter }) =>
      state.setIn(["comboCounter"], counter),
  },
  initialState,
);

// Constants
const emptyArray = [];
const emptyNull = null;
const emptyObject = {};

export const selectors = {
  // Booleans
  configExists: (state) => !!state.game.config,

  // Getters
  getConfig: (state) => state.game.config,
  getComboCounter: (state) => state.game.comboCounter,
  getUserConfig: (state) =>
    state.game.config && state.game.config.data.userConfig,
  getQuestions: (state) =>
    state.game.config
      ? (state.game.config.data.questions.asMutable
          ? state.game.config.data.questions.asMutable({ deep: true })
          : state.game.config.data.questions) || emptyArray
      : emptyArray,
  getPlayers: (state) =>
    state.game.config && state.game.config.userConfig
      ? state.game.config.userConfig.players || emptyArray
      : emptyArray,
  getDesignConfig: (state) =>
    state.game.config
      ? state.game.config.data.designConfig || emptyObject
      : emptyObject,
  getResultsConfig: (state) => state.game.results,
  getCurrentScore: (state) => state.game.currentScore,
  getGameLength: (state) =>
    state.game.config ? state.game.config.data.questions.length : 0,
  getGameStatus: (state) =>
    state.game.config ? state.game.config.status : undefined,
  getBonusConfig: (state) =>
    state.game.config ? state.game.config.data.bonusConfig : undefined,
  getGameTitle: (state) => (state.game.config ? state.game.config.title : ""),
  getQuestionIndex: (state) => state.game.questionIndex,

  // My Info
  myId: (state) =>
    state.game.config && state.game.config.userConfig
      ? state.game.config.userConfig.userId || emptyNull
      : emptyNull,
};
