import * as app from "./app/redux";
import * as game from "./game/redux";
import * as translation from "./translation/redux";

const entities: any = {
  app,
  game,
  translation,
};

export default entities;
