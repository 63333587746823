import {
  all,
  put,
  takeLatest,
  takeEvery,
  delay,
  select,
} from "redux-saga/effects";
import FAKE_DATA from "../../data.json";
import FAKE_RESULTS from "../../results.json";

// Redux
import { actions as TranslationActions } from "../translation/redux";
import { actions as GameActions } from "./redux";
import { selectors as GameSelectors } from "./redux";

// SDK
import GeniusBDK from "geniusbdk";
const geniusbdk = new GeniusBDK();

export default class GameSagas {
  // Configuration
  static *getGameConfig() {
    const config = yield geniusbdk.getConfiguration();
    // const config = FAKE_DATA;

    if (config) {
      if (config.data.userConfig && config.data.userConfig.lang) {
        yield put(
          TranslationActions.setLanguage(
            config.data.userConfig.lang,
            config.data.userConfig.path,
          ),
        );
      }
      yield put(GameActions.requestSetConfig(config));
    }
  }

  static *addScore() {
    const score = yield select(GameSelectors.getCurrentScore);

    yield geniusbdk.updateMyScore(score);
    delay(1000);

    const results = yield geniusbdk.getResults();
    // const results = FAKE_RESULTS;
    if (results) {
      yield put(GameActions.requestSetResults(results));
    }
  }

  static *getGameResults() {
    const results = yield geniusbdk.getResults();

    if (results) {
      yield put(GameActions.requestSetResults(results));
    }
  }

  // Other
  static *completeGame() {
    yield geniusbdk.completeGame({ proposeGames: true });
  }

  // Other
  static *closeGame() {
    yield geniusbdk.closeGame();
  }

  static *loop() {
    yield all([
      // Configuration
      takeLatest(
        GameActions.requestGetConfig.getType(),
        GameSagas.getGameConfig,
      ),

      // Other
      takeEvery(GameActions.requestAddScore.getType(), GameSagas.addScore),
      takeEvery(
        GameActions.requestGetResults.getType(),
        GameSagas.getGameResults,
      ),

      takeLatest(
        GameActions.requestCompleteGame.getType(),
        GameSagas.completeGame,
      ),
      takeLatest(GameActions.requestCloseGame.getType(), GameSagas.closeGame),
    ]);
  }
}
