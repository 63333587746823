import React, { useCallback, useMemo } from "react";
import { useIonRouter } from "@ionic/react";
import { useGame } from "../../redux/game/hooks";
import { useLocalize } from "../../redux/translation/localize";

import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import InstructionsSlides from "./partials/InstructionsSlides/InstructionsSlides";

import "swiper/css";
import "./Instructions.scss";
import timer from "../../assets/img/Instructions-timer.png";
import comboImg from "../../assets/img/Instructions-combo.png";
import comboAccImg from "../../assets/img/Instructions-combo-acc.png";
import rightImg from "../../assets/img/Instructions-right.png";
import wrongImg from "../../assets/img/Instructions-wrong.png";

const INSCTRUCTIONS = [
  {
    toVerify: [],
    img: rightImg,
    label: "instructions_success",
  },
  {
    toVerify: ["comboBonus"],
    img: comboImg,
    label: "instructions_bonus",
  },
  {
    toVerify: ["comboBonus", "comboAccumulationBonus"],
    img: comboAccImg,
    label: "instructions_bonus_acc",
  },
  {
    toVerify: [],
    img: wrongImg,
    label: "instructions_fail",
  },
  {
    toVerify: ["timerBonus"],
    img: timer,
    label: "instructions_bonus_timer",
  },
];

interface Instruction {
  toVerify: string[];
  img: string;
  label: string;
}

const Instructions: React.FC = () => {
  const t = useLocalize();
  const { push } = useIonRouter();
  const { bonusConfig, questionIndex } = useGame();

  const nextSlide = useCallback(() => {
    const el: any = document.getElementById("instructionSwiper");
    if (!!el?.swiper) {
      el.swiper.slideNext(500, false);
    }
  }, []);

  const previousSlide = useCallback(() => {
    const el: any = document.getElementById("instructionSwiper");
    if (!!el?.swiper) {
      el.swiper.slidePrev(500, false);
    }
  }, []);

  const startGame = useCallback(() => {
    const elem = document.getElementById("instructionPage");
    if (!elem) return;
    elem.style.opacity = "0";
    setTimeout(() => push(`/question/${questionIndex + 1}`), 1000);
  }, [push, questionIndex]);

  const instructionCheck = useCallback(
    (instruction: Instruction) => {
      const isIncluded = (el: string) => el && bonusConfig[el];
      return instruction.toVerify.every(isIncluded);
    },
    [bonusConfig],
  );

  const instructionLen = useMemo(
    () =>
      INSCTRUCTIONS.reduce((acc: number, instruction: Instruction) => {
        if (instructionCheck(instruction)) acc++;
        return acc;
      }, 0),
    [instructionCheck],
  );

  const ActiveSlides = useMemo(
    () =>
      INSCTRUCTIONS.filter((instruction: Instruction) =>
        instructionCheck(instruction),
      ),
    [instructionCheck],
  );

  return (
    <div id="instructionPage" className="page">
      <Header instructions />
      <div className="instructionBody">
        <div className="instructionTitle">{t("instructions")}</div>
        <Swiper
          id="instructionSwiper"
          slidesPerView={1}
          allowTouchMove
          centeredSlides
        >
          {ActiveSlides.map((instruction: Instruction, index: number) => {
            return (
              <SwiperSlide className="instructionSlides" key={index}>
                <InstructionsSlides
                  image={instruction.img}
                  index={index}
                  length={instructionLen}
                  instructions={t(instruction.label)}
                  prev={previousSlide}
                  next={nextSlide}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Button label={t("lets_play")} onClick={startGame} />
      </div>
    </div>
  );
};

export default Instructions;
