import Point from "../partials/Point";

// Constants
const LINE_DURATION = 2;
const LINE_COLOR = "red";
const LINE_WIDTH_START = 3;
const SPREAD: string = ""; //"thin";

const points: Point[] = [];

export const addPoint = (x: number, y: number, cb?: Function) => {
  let point = new Point(x, y, 0);
  const lastPoint = points[points.length - 1];

  points.push(point);
  cb?.(x, y, lastPoint?.x, lastPoint?.y);
};

export const animatePoints = (
  context: CanvasRenderingContext2D,
  color?: "rainbow" | string,
) => {
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);

  let point: Point;
  const duration = (LINE_DURATION * 1000) / 60;

  for (let i = 0; i < points.length; i++) {
    point = points[i];

    point.lifetime += 1;

    if (point.lifetime > duration) {
      points.splice(i, 1);
      continue;
    }
  }

  drawWithShadow(context, points, 1, 0, color || "rainbow");
  drawWithShadow(context, points, 2, 0, color || "rainbow");
  drawWithShadow(context, points, 3, Math.PI, color || "rainbow");
  drawWithShadow(context, points, 4, Math.PI, color || "rainbow");
  drawWithShadow(context, points, 8, Math.PI * 2.5, color || "rainbow");
  drawWithShadow(context, points, 12, Math.PI * 2.5, color || "rainbow");
};

function drawWithShadow(
  context: any,
  points: any,
  dist: number,
  angle: number,
  color: string,
) {
  drawPoints(context, points, dist + 2, angle, color);
  drawPoints(context, points, dist, angle, "rgba(0, 0, 0, 0.1)");
}

function drawPoints(
  context: any,
  points: any,
  dist: number,
  angle: number,
  color: string,
) {
  if (points.length < 2) return;
  let lastPoint = movePoint(points[0], points[1], dist, angle);
  let prevPoint = points[0];
  const duration = (LINE_DURATION * 1000) / 60;
  for (var i = 1; i < points.length; i++) {
    let point = points[i];
    const dx = point.x - prevPoint.x;
    const dy = point.y - prevPoint.y;
    const ang = Math.atan2(dy, dx) + angle;
    const px = Math.sin(ang) * dist + point.x;
    const py = Math.cos(ang) * dist + point.y;

    const inc = point.lifetime / duration; // 0 to 1 over LINE_DURATION

    let spreadRate;
    if (SPREAD === "thin") {
      spreadRate = LINE_WIDTH_START / (point.lifetime * 2);
    } else {
      spreadRate = LINE_WIDTH_START * (1 - inc);
    }

    context.strokeStyle = color || LINE_COLOR;
    if (color === "rainbow") {
      context.strokeStyle = `hsl(${(360 * i) / points.length},80%,50%)`;
    }
    context.lineJoin = "miter";
    context.lineWidth = spreadRate;

    context.beginPath();
    context.moveTo(lastPoint.x, lastPoint.y);
    context.lineTo(px, py);
    context.stroke();
    context.closePath();
    lastPoint = { x: px, y: py };
    prevPoint = point;
  }
}

function movePoint(prevPoint: any, point: any, dist: number, angle: number) {
  const dx = point.x - prevPoint.x;
  const dy = point.y - prevPoint.y;
  const ang = Math.atan2(dy, dx) + angle;
  const px = Math.sin(ang) * dist + point.x;
  const py = Math.cos(ang) * dist + point.y;
  return { x: px, y: py };
}
