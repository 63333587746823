import React, { useCallback, useMemo } from "react";
import { useUpdateGame } from "../../redux/game/hooks";

import Icon from "../Icon/Icon";

import "./CloseButton.scss";
import icons from "../../types/IconTypes";

interface Props {
  noMargin?: boolean;
  inGame?: boolean;
  goBack: boolean;
}

const CloseButton: React.FC<Props> = ({ noMargin, inGame, goBack }) => {
  const { closeGame } = useUpdateGame();

  const handleEnd = useCallback(() => {
    if (goBack) window.location.href = "/";
    else closeGame();
  }, [closeGame, goBack]);

  const buttonStyle = useMemo(
    () => ({
      margin: noMargin ? "0 1.25rem" : "1.25rem",
      width: inGame ? "1.5rem" : "2.188rem",
      height: inGame ? "1.5rem" : "2.188rem",
    }),
    [inGame, noMargin],
  );

  return (
    <div className="closeButton" onClick={handleEnd} style={buttonStyle}>
      <Icon className="closeIcon" icon={icons.CLOSE} />
    </div>
  );
};

export default CloseButton;
