import React from "react";
import "./Loader.scss";

const Loader: React.FC<{ size?: string }> = ({ size = "small" }) => (
  <div className="gameLoader">
    <div className={`gameLoader-${size}`}>
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
