import React, { useCallback, useEffect, useRef } from "react";
import { setCanvasHD } from "../../utils/CanvasHD";

// Style & Utils
import "./MouseTrail.scss";
import { addPoint, animatePoints } from "./utils/animatePoints";

// Constants
const CANVAS_ID = "MouseTrail";
const DRAW_EVERY_FRAME = 1;
const emptyFunction = () => null;

interface MouseTrailProps {
  color?: "rainbow" | string;
  onMouseMove: (x: number, y: number, lastX: number, lastY: number) => void;
}

const MouseTrail: React.FC<MouseTrailProps> = ({ color, onMouseMove }) => {
  const mouseStatus = useRef<boolean>(false);

  // Ref
  const frame = useRef<number>(0);

  // Handlers
  const handleResize = useCallback(() => setCanvasHD(CANVAS_ID), []);

  const draw = useCallback(() => {
    const canvas: any = document.getElementById(CANVAS_ID);
    const context = canvas?.getContext?.("2d");
    if (!canvas || !context) return;
    animatePoints(context, color);
  }, [color]);

  const handleMouseStatus = useCallback(
    () => (mouseStatus.current = !mouseStatus.current),
    [mouseStatus],
  );

  const handleAddPoint = useCallback(
    (x: number, y: number) => {
      addPoint(x, y, onMouseMove);
    },
    [onMouseMove],
  );

  const handleMove = useCallback(
    (ev) => {
      if (frame.current === DRAW_EVERY_FRAME) {
        if (ev instanceof MouseEvent) {
          if (mouseStatus.current) {
            handleAddPoint(ev.clientX + 100, ev.clientY + 100);
          }
          frame.current = 0;
        } else if (ev instanceof TouchEvent && ev.changedTouches[0]) {
          handleAddPoint(
            ev.changedTouches[0].clientX,
            ev.changedTouches[0].clientY,
          );
          frame.current = 0;
        }
      }
      frame.current++;
    },
    [handleAddPoint],
  );

  useEffect(() => {
    setCanvasHD(CANVAS_ID);

    let isDrawingLine = true;
    const drawLine = () => {
      draw();
      if (isDrawingLine) requestAnimationFrame(drawLine);
    };
    drawLine();

    window.addEventListener("resize", handleResize);

    //********* Mouse Listeners *********//
    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mousedown", handleMouseStatus);
    document.addEventListener("mouseup", handleMouseStatus);

    //********* Touch Listeners *********//
    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchleave", emptyFunction);

    return () => {
      isDrawingLine = false;
      mouseStatus.current = false;
      window.removeEventListener("resize", handleResize);

      //********* Mouse Listeners *********//
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseover", emptyFunction);
      document.removeEventListener("mouseleave", emptyFunction);

      //********* Touch Listeners *********//
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchleave", emptyFunction);
    };
  }, []);
  return <canvas id={CANVAS_ID} />;
};

export default MouseTrail;
