const PIXEL_RATIO = (() => {
  const ctx: any = document.createElement("canvas").getContext("2d");
  const dpr = window.devicePixelRatio || 1;
  const bsr =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

  return dpr / bsr;
})();

const createCanvasHD = (width?: number, height?: number, useRatio = false) => {
  const ratio = useRatio ? PIXEL_RATIO : 1;
  const canvas = document.createElement("canvas");

  canvas.width = Math.floor((width || window.innerWidth) * ratio);
  canvas.height = Math.floor((height || window.innerHeight) * ratio);
  canvas.style.width = canvas.width + "px";
  canvas.style.height = canvas.height + "px";
  canvas.getContext("2d")?.setTransform(ratio, 0, 0, ratio, 0, 0);
  return canvas;
};

export const setCanvasHD = (
  canvasId: string,
  width?: number,
  height?: number,
  useRatio = false,
) => {
  const ratio = useRatio ? PIXEL_RATIO : 1;
  const canvas: any = document.getElementById(canvasId);
  if (!canvas) return;
  canvas.width = Math.floor((width || window.innerWidth) * ratio);
  canvas.height = Math.floor((height || window.innerHeight) * ratio);
  canvas.style.width = canvas.width + "px";
  canvas.style.height = canvas.height + "px";
  canvas.getContext("2d")?.setTransform(ratio, 0, 0, ratio, 0, 0);
};

export default createCanvasHD;
