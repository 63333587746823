import React from "react";
import { IonContent, IonModal } from "@ionic/react";
import { useLocalize } from "../../../../redux/translation/localize";

import UserCard from "../../../../components/UserCard/UserCard";

import "./Ranking.scss";
import { User } from "../../../../utils/DataTypes.interface";
const BREAK = 0.3;

interface RankingProps {
  isOpen: boolean;
  users: User[];
}

const Ranking: React.FC<RankingProps> = ({ isOpen, users }) => {
  const t = useLocalize();

  return (
    <IonModal
      animated
      isOpen={isOpen}
      initialBreakpoint={BREAK}
      breakpoints={[0.07, BREAK, 0.85, 0.99]}
      handle={true}
      showBackdrop={false}
      backdrop-dismiss={false}
      swipeToClose={true}
      backdropBreakpoint={BREAK}
    >
      <IonContent>
        <div className="rankingTitle">{t("ninja_rankin_10")}</div>
        {!!users?.length && (
          <div className="board">
            {users.map((user: User, index: number) => (
              <UserCard key={index} user={user} index={index} />
            ))}
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default Ranking;
