import { useHistory } from "react-router";
import { useLocalize } from "../../redux/translation/localize";
import {
  useGame,
  useUpdateGame,
  useComboCounter,
} from "../../redux/game/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Components
import Fruit from "../../components/Fruit/Fruit";
import Header from "../../components/Header/Header";
import FeedBack from "../../components/FeedBack/FeedBack";
import MouseTrail from "../../components/MouseTrail/MouseTrail";
import Backgrounds from "../../components/Backgrounds/Backgrounds";

// Style & Utils
import "./Game.scss";
import { Answer } from "../../utils/DataTypes.interface";
import { setCanvasHD } from "../../utils/CanvasHD";
import backgroundImg from "../../assets/img/ninja_game_background.png";
import random from "../../components/MouseTrail/utils/random";
import timer from "../../assets/img/Instructions-timer.png";

// Constants
const CANVAS_ID = "Fruits";
let fruits: Fruit[] = [];
let feedBacks: FeedBack[] = [];

const Game: React.FC = () => {
  const { push } = useHistory();
  const t = useLocalize();
  const { setQuestionIndex, setCurrentScore, sendScore } = useUpdateGame();
  const { comboCounter, updateCounter } = useComboCounter();
  const { questions, questionIndex, gameLength, bonusConfig, currentScore } =
    useGame();

  // Refs
  const points = useRef<number>(
    bonusConfig?.comboAccumulationBonus && currentScore ? currentScore : 0,
  );
  const counter = useRef<number>(comboCounter);
  const answers = useRef<Answer[]>(questions[questionIndex]?.answers);
  const drawInterval = useRef<NodeJS.Timer | null>(null);
  const generateInterval = useRef<NodeJS.Timer | null>(null);

  //states
  const [startTimer, setStart] = useState<boolean>(false);
  const [addBlur, setAddBlur] = useState<boolean>(false);
  const [blurColor, setBlurColor] = useState<string>("transparent");
  const [timerBonus, setTimerBonus] = useState<number>(0);

  const bonusStyle = useMemo(
    () => (timerBonus > 0 ? "timerPointIn" : "timerPointOut"),
    [timerBonus],
  );

  // Handlers

  const flash = useCallback((color: string) => {
    setAddBlur(true);
    setBlurColor(color);
    setTimeout(() => {
      setAddBlur(false);
      setBlurColor("transparent");
    }, 200);
  }, []);

  const createFeedBack = useCallback(
    (
      y: number,
      x: number,
      id: string,
      text: string,
      color: string,
      fontSize: string,
    ) => {
      const heightOffset = window.innerHeight - 50;
      const widthOffset = window.innerWidth - 50;
      let yToUSe = y < 0 ? 100 : y;
      if (y >= heightOffset) yToUSe = window.innerHeight - 100;
      let xToUSe = x < 0 ? 100 : x;
      if (x >= widthOffset) xToUSe = window.innerWidth - 100;
      const newFeedBack = new FeedBack({
        y: yToUSe,
        x: xToUSe,
        id,
        text,
        color,
        fontSize,
      });
      feedBacks.push(newFeedBack);
    },
    [],
  );

  const handleMouseMove = useCallback(
    (x: number, y: number, lastX: number, lastY: number) => {
      fruits.forEach((fruit) => {
        if (!fruit.sliced && fruit.checkPosition(x, y)) {
          const index = answers.current.findIndex(
            (item: Answer) => item.statusId === fruit.id,
          );

          if (index >= 0) {
            const answer = answers.current[index];

            answer.statusId = null;
            answer.sliced = answer.isTrue;
            answers.current[index] = answer;

            if (answer.isTrue) {
              flash("green");
              points.current += counter.current;
              createFeedBack(y + 60, x + 60, fruit.id, "+1pts", "green", "25");
              if (counter.current > 1) {
                createFeedBack(
                  y - 90,
                  x - 60,
                  fruit.id,
                  `COMBO +${counter.current}pts`,
                  "green",
                  "20",
                );
              }
              if (bonusConfig.comboBonus) {
                ++counter.current;
              }
            } else {
              flash("red");
              createFeedBack(y, x, fruit.id, "X", "red", "44");
              counter.current = 1;
            }
          }
          fruit.slice(x, y, lastX, lastY);
        }
      });
    },
    [bonusConfig?.comboBonus, createFeedBack, flash],
  );

  const handleResize = useCallback(() => setCanvasHD(CANVAS_ID), []);

  const handleDelete = useCallback((id) => {
    fruits = fruits.filter((fruit: Fruit) => id !== fruit.id);
    const answer = answers.current.find((item: Answer) => item.statusId === id);
    if (answer) {
      answer.statusId = null;
    }
  }, []);

  const handleDeleteFeedBack = useCallback((id) => {
    feedBacks = feedBacks.filter((feedback: FeedBack) => id !== feedback.id);
  }, []);

  const handleStop = useCallback(
    (timer: number) => {
      if (drawInterval.current) clearInterval(drawInterval.current);
      if (generateInterval.current) clearInterval(generateInterval.current);
      setStart(false);

      setCurrentScore(
        bonusConfig?.timerBonus ? points.current + timer : points.current,
      );
      setQuestionIndex(questionIndex + 1);

      const elem = document.getElementById("gameContent");
      if (!elem) return;
      if (bonusConfig?.comboAccumulationBonus) updateCounter(counter.current);
      if (questionIndex + 1 === gameLength) sendScore();

      setTimeout(() => {
        elem.style.opacity = "0";
        setTimerBonus(bonusConfig?.timerBonus ? timer : 0);
      }, 500);
      setTimeout(() => {
        setTimerBonus(0);
        push(
          questionIndex + 1 === gameLength
            ? "/finish"
            : `/question/${questionIndex + 1}`,
        );
      }, 2050);
    },
    [
      setCurrentScore,
      bonusConfig?.timerBonus,
      bonusConfig?.comboAccumulationBonus,
      setQuestionIndex,
      questionIndex,
      updateCounter,
      gameLength,
      sendScore,
      push,
    ],
  );

  // Fruits Management
  const drawFruits = useCallback(
    (context: CanvasRenderingContext2D, timeScale: number) => {
      if (!context) return;
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      fruits.forEach((fruit) => fruit.draw(context, timeScale, handleDelete));
      feedBacks.forEach(
        (feedback) => feedback?.draw(context, handleDeleteFeedBack),
      );
      // setStart(true);
    },
    [handleDelete, handleDeleteFeedBack],
  );

  const generateFruits = useCallback(() => {
    let datas = answers.current.filter(
      (item: Answer) => !item.statusId && !item.sliced,
    );
    let index = random(0, datas.length - 1);

    if (random(0, 35) > 35 && datas.some((item) => item.isTrue)) {
      datas = datas.filter((item: Answer) => item.isTrue);
      index = random(0, datas.length - 1);
    }
    const fruitValue: any = datas[index];

    if (!fruitValue) return;

    const newFruit = new Fruit({
      drag: 1,
      onGround: false,
      type: fruitValue.type,
      source: fruitValue.type === "text" ? fruitValue.answer : fruitValue.url,
      style: {
        color: "#000",
        fontSize: 36,
      },
    });

    //@ts-ignore
    datas[index].statusId = newFruit.id;
    fruits.push(newFruit);
  }, [answers]);

  useEffect(() => {
    fruits = [];
    let runningGame = true;
    let popInterval: any;
    setTimeout(() => {
      setCanvasHD(CANVAS_ID);
      setStart(true);
      const canvas: any = document.getElementById(CANVAS_ID);
      const context: CanvasRenderingContext2D = canvas?.getContext?.("2d");

      let lastTime = Number(new Date());
      const runGame = () => {
        const now = Number(new Date());
        const delta = now - lastTime;
        lastTime = now;
        const timeScale = delta / 16.6;
        drawFruits(context, timeScale);
        if (runningGame) requestAnimationFrame(runGame);
      };
      runGame();

      generateFruits();
      popInterval = setInterval(() => {
        generateFruits();
      }, 1800);

      window.addEventListener("resize", handleResize);
    }, 100);

    return () => {
      runningGame = false;
      window.removeEventListener("resize", handleResize);
      clearInterval(popInterval);
    };
  }, []);

  return (
    <div id="Game" className="page">
      <div id="gameContent">
        <Backgrounds
          blur={addBlur}
          color={blurColor}
          url={backgroundImg}
          gradient
        />
        <Header
          game
          start={startTimer}
          stop={handleStop}
          answers={answers.current}
          point={points.current}
        />
        <MouseTrail onMouseMove={handleMouseMove} color="white" />
        <canvas id={CANVAS_ID} />
      </div>
      <div className={bonusStyle}>
        <img className="timerPointIcon" src={timer} alt="timer icon" />
        <div className="timerPointHint">
          {t("timer_bonus", { timer: timerBonus.toString() })}
        </div>
      </div>
    </div>
  );
};

export default Game;
