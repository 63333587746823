import abs from "../MouseTrail/utils/abs";
import { FruitType } from "./types/Fruit.types";
import { getLongestString } from "../../utils/stringUtils";
import getClippedRegion from "./utils/getClippedRegion";
import random, { randomFloat } from "../MouseTrail/utils/random";
import "../../assets/fonts/SF-Pro-Display-Black.otf";
import imageCache from "../../utils/imageCache";
import textCache from "../../utils/textCache";

// Constants
const GRAVITY = 0.09;
const defaultSize = {
  height: 200,
  width: 200,
};

class Fruit {
  // Data Variabless
  public id: string;
  public source: string;
  public type: "image" | "text";
  public size?: {
    height: number;
    width: number;
  };
  public lines: string[] = [];

  // Image Variables
  public image?: HTMLImageElement;
  public imageLoaded: boolean = false;

  // Movement Variables
  public y: number;
  public x: number;
  public dy: number;
  public dx: number;
  public drag: number = 1;
  public onGround: boolean = false;

  public part1: HTMLCanvasElement | null;
  public part2: HTMLCanvasElement | null;

  // Sliced Variables
  public sliced: boolean = false;
  public slicedPart?: {
    x: number;
    y: number;
  };
  public slicedParams?: {
    x: number;
    y: number;
    direction: "horizontal" | "vertical";
  };

  // Optional style
  public style?: {
    color?: string;
    fontSize?: number;
  };

  // Define class constructor
  constructor(fruit: FruitType) {
    this.id = `fruit_${(Math.random() * 1000).toFixed(0)}${Date.now()}`;
    this.source = fruit.source;
    this.type = fruit.type;
    this.part1 = null;
    this.part2 = null;

    if (this.type === "image") {
      this.size = defaultSize;
      this.image = imageCache.get(this.source);
      if (!this.image) throw new Error("image not in cache " + this.source);
      this.imageLoaded = true;
      this.size = {
        height: defaultSize.height,
        width: (defaultSize.height * this.image.width) / this.image.height,
      };
    }

    // Manage initial position
    this.y = window.innerHeight;
    this.x = random(0, window.innerWidth);

    // Manage Directions
    const halfScreen = window.innerWidth / 2;

    const dy = window.innerHeight / 100;
    this.dy = -randomFloat(dy + 1, dy + 2);

    if (this.x < halfScreen) {
      this.dx =
        this.x >= halfScreen / 2 ? randomFloat(1, 1.5) : randomFloat(1.5, 2);
    } else {
      this.dx =
        this.x <= halfScreen + halfScreen / 2
          ? -randomFloat(1, 1.5)
          : -randomFloat(0.5, 2.5);
    }

    this.style = fruit.style;
    this.onGround = !!fruit.onGround;
    this.drag = fruit.drag || this.drag;
  }

  // Draw the fruit
  draw(
    context: CanvasRenderingContext2D,
    timeScale: number,
    onDelete: (id: string, onGround: boolean) => void,
  ) {
    if ((this.type === "image" && !this.imageLoaded) || !context) return;
    if (this.sliced) {
      this.drawSliced(context, timeScale, onDelete);
      return;
    }

    this.dy += GRAVITY * timeScale;
    this.dy *= this.drag;
    this.dx *= this.drag;
    this.x += this.dx * timeScale;
    this.y += this.dy * timeScale;
    this.onGround = this.y >= window.innerHeight + 100;

    if (this.type === "text") {
      this.setTextContext(context, this.x, this.y);
      this.lines = textCache.get(this.source) || [];

      if (!this.size) {
        const lgst: string =
          this.lines.length > 1
            ? getLongestString(this.lines) || this.source
            : this.source;
        const metrics = context.measureText(lgst);
        const fontHeight =
          metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        // const actualHeight =
        //   metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        this.size = {
          width: metrics.width,
          height:
            this.lines.length > 1 ? fontHeight * this.lines.length : fontHeight,
        };
      }
    } else if (this.type === "image" && this.image) {
      context.drawImage(
        this.image,
        this.x,
        this.y,
        this.size?.width || 0,
        this.size?.height || 0,
      );
    }

    if (this.onGround) {
      onDelete(this.id, true);
    }
  }

  // Draw sliced Fruits
  drawSliced(
    context: CanvasRenderingContext2D,
    timeScale: number,
    onDelete: (id: string, onGround: boolean) => void,
  ) {
    if (
      !context ||
      !this.sliced ||
      !this.size ||
      !this.slicedParams ||
      !this.slicedPart
    )
      return;
    if (!this.part1 && !this.part2) {
      const part1: HTMLCanvasElement | null = getClippedRegion(
        this.size,
        this.slicedParams.x,
        this.slicedParams.y,
        this.slicedParams.direction,
        false,
      );
      const part2: HTMLCanvasElement | null = getClippedRegion(
        this.size,
        this.slicedParams.x,
        this.slicedParams.y,
        this.slicedParams.direction,
        true,
      );
      if (!part1 || !part2) return;

      const p1Ctx = part1.getContext("2d");
      const p2Ctx = part2.getContext("2d");
      if (!p1Ctx || !p2Ctx) return;
      if (this.type === "text") {
        this.setTextContext(p1Ctx, this.size.width / 2, 0);
        this.setTextContext(p2Ctx, this.size.width / 2, 0);
      } else if (this.type === "image" && this.image) {
        p1Ctx.drawImage(this.image, 0, 0, part1.width, part1.height);
        p2Ctx.drawImage(this.image, 0, 0, part2.width, part2.height);
      }
      this.part1 = part1;
      this.part2 = part2;
    }
    if (!this.part1 || !this.part2) return;
    // Position
    this.dy += GRAVITY * timeScale;
    this.dy *= this.drag;
    this.dx *= this.drag;
    this.x -= this.dx * timeScale;
    this.y += this.dy * timeScale;
    this.slicedPart.x += this.dx * timeScale;
    this.slicedPart.y += this.dy * timeScale;

    // Draw

    if (this.type === "text") {
      context.drawImage(this.part1, this.x, this.y);
      context.drawImage(this.part2, this.slicedPart.x, this.slicedPart.y);
    } else if (this.type === "image" && this.image) {
      context.drawImage(
        this.part1,
        this.x,
        this.y,
        this.part1.width,
        this.part1.height,
      );
      context.drawImage(
        this.part2,
        this.slicedPart.x,
        this.slicedPart.y,
        this.part1.width,
        this.part1.height,
      );
    }

    // Check to delete
    const { innerHeight, innerWidth } = window;
    if (
      (this.y > innerHeight + this.size.height ||
        this.x < 0 - this.size.width ||
        this.x > innerWidth + this.size.width) &&
      (this.slicedPart.y > innerHeight + this.size.height ||
        this.slicedPart.x < 0 - this.size.width ||
        this.slicedPart.x > innerWidth + this.size.width)
    ) {
      onDelete(this.id, true);
    }
  }

  // Check Fruit Position
  checkPosition(x: number, y: number) {
    const { height, width } = defaultSize;
    const widthToUse = this.size?.width || width;
    const xToUse = this.type === "text" ? this.x - widthToUse / 2 : this.x;
    const isTouchedX = x <= xToUse + (this.size?.width || width) && x >= xToUse;
    const isTouchedY =
      y <= this.y + (this.size?.height || height) && y >= this.y;
    return isTouchedX && isTouchedY;
  }

  // Slice Fruit
  slice(x: number, y: number, lastX: number, lastY: number) {
    const sliceX = abs(x - lastX);
    const sliceY = abs(y - lastY);
    this.sliced = true;
    this.dy = 2.5;
    this.dx = 5;
    this.slicedPart = {
      x: this.x,
      y: this.y,
    };
    this.slicedParams = {
      x: abs(x - this.x),
      y: abs(y - this.y),
      direction: sliceX < sliceY ? "vertical" : "horizontal",
    };
  }

  // Default settings
  setTextContext(
    context: CanvasRenderingContext2D | null,
    x: number,
    y: number,
  ) {
    const { fontSize, color } = this.style || {};
    if (!context) return;
    context.strokeStyle = "white";
    context.lineWidth = 2;
    const size = fontSize || 46;
    context.fillStyle = color || "black";
    context.font = `${size}px SF-Pro-Display-Black`;
    const lineheight = size;
    context.shadowColor = "rgba(0, 0, 0, .2)";
    context.shadowBlur = 8;
    context.shadowOffsetX = 5;
    context.shadowOffsetY = 5;
    context.textAlign = "center";

    for (let i = 0; i < this.lines.length; i++) {
      context.fillText(this.lines[i], x, y + i * lineheight + lineheight);
      context.strokeText(this.lines[i], x, y + i * lineheight + lineheight);
    }
  }
}

export default Fruit;
