import { CoordType } from "../types/MouseTrail.types";

class Point {
  public x: number = 0;
  public y: number = 0;
  public lifetime: number = 0;
  public flip: boolean = false;

  // Define class constructor
  constructor(
    x: number,
    y: number,
    lifetime: number = 0,
    flip: boolean = false,
  ) {
    this.x = x;
    this.y = y;
    this.lifetime = lifetime;
    this.flip = flip;
  }

  // Get the distance between a & b
  static distance = (a: CoordType, b: CoordType) => {
    const dx = a.x - b.x;
    const dy = a.y - b.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Get the mid point between a & b
  static midPoint = (a: CoordType, b: CoordType) => {
    const mx = a.x + (b.x - a.x) * 0.5;
    const my = a.y + (b.y - a.y) * 0.5;
    return new Point(mx, my);
  };

  // Get the angle between a & b
  static angle = (a: CoordType, b: CoordType) => {
    const dx = a.x - b.x;
    const dy = a.y - b.y;

    return Math.atan2(dy, dx);
  };

  // Simple getter for printing
  get pos() {
    return this.x + "," + this.y;
  }
}

export default Point;
