import React from "react";

import Timer from "../../components/Timer/Timer";
import CloseButton from "../../atoms/CloseButton/CloseButton";

import "./Header.scss";
import { Answer } from "../../utils/DataTypes.interface";

interface HeaderProps {
  instructions?: boolean;
  question?: boolean;
  game?: boolean;
  start?: boolean;
  stop?: (timer: number) => void;
  answers?: Answer[];
  point?: number;
}

const Header: React.FC<HeaderProps> = ({
  instructions,
  game,
  start,
  stop,
  answers,
  point,
  question,
}) => {
  return (
    <div className="gameHeader">
      <div className="timerAndClose">
        {start && !!stop && !!answers && (
          <Timer start={start} stop={stop} answers={answers} />
        )}
        <CloseButton
          inGame={game}
          goBack={game || instructions || question || false}
          noMargin={instructions}
        />
      </div>
      {game && (
        <div className="points">
          {point}
          <span>pts</span>
        </div>
      )}
    </div>
  );
};

export default Header;
