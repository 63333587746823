import React, { memo } from "react";
import { connect, useSelector } from "react-redux";
import { getTranslateFunction } from "redux-i18n";

const mapSelectors = (state) => ({
  translations: state.i18nState.translations,
  translation: state.i18nState.translations[state.i18nState.lang],
  lang: state.i18nState.lang, // make the "Component" aware about the language, to handle specific action if it changes
});

export const Wrapper = () => (Component) => {
  const wrapped = (props) => {
    const translateFunction = getTranslateFunction(
      props.translations,
      props.lang,
    );
    const t = (key, params = null) => translateFunction(key, params);

    return <Component {...props} t={t} />;
  };

  const WrapperComponent = connect(mapSelectors)(memo(wrapped));
  WrapperComponent.navigationOptions = Component.navigationOptions;
  return WrapperComponent;
};

export const useLocalize = () => {
  const translations = useSelector((state) => state.i18nState.translations);
  const lang = useSelector((state) => state.i18nState.lang);

  const t = getTranslateFunction(translations, lang);
  return t;
};
