import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as GameActions, selectors as GameSelectors } from "./redux";

export const useGameConfig = () => {
  const dispatch = useDispatch();

  const getConfig = useCallback(
    () => dispatch(GameActions.requestGetConfig()),
    [dispatch],
  );

  const gameConfig = useSelector(GameSelectors.getConfig);
  const configExists = useSelector(GameSelectors.configExists);

  return {
    configExists,
    gameConfig,
    getConfig,
  };
};

export const useCurrentUser = () => {
  const currentUser = useSelector(GameSelectors.getUserConfig);

  return {
    currentUser,
  };
};

export const useUpdateGame = () => {
  const dispatch = useDispatch();

  const closeGame = useCallback(
    () => dispatch(GameActions.requestCloseGame()),
    [dispatch],
  );
  const setQuestionIndex = useCallback(
    (questionIndex) =>
      dispatch(GameActions.requestSetQuestionIndex(questionIndex)),
    [dispatch],
  );
  const setCurrentScore = useCallback(
    (currentScore) =>
      dispatch(GameActions.requestSetCurrentScore(currentScore)),
    [dispatch],
  );
  const sendScore = useCallback(
    () => dispatch(GameActions.requestAddScore()),
    [dispatch],
  );

  return {
    closeGame,
    sendScore,
    setCurrentScore,
    setQuestionIndex,
  };
};

export const useGame = () => {
  const designConfig = useSelector(GameSelectors.getDesignConfig);
  const gameStatus = useSelector(GameSelectors.getGameStatus);
  const gameLength = useSelector(GameSelectors.getGameLength);
  const gameTitle = useSelector(GameSelectors.getGameTitle);
  const questions = useSelector(GameSelectors.getQuestions);
  const questionIndex = useSelector(GameSelectors.getQuestionIndex);
  const bonusConfig = useSelector(GameSelectors.getBonusConfig);
  const currentScore = useSelector(GameSelectors.getCurrentScore);

  return {
    gameTitle,
    gameStatus,
    gameLength,
    questions,
    designConfig,
    questionIndex,
    bonusConfig,
    currentScore,
  };
};

export const usePlayers = () => {
  const players = useSelector(GameSelectors.getPlayers);
  return players;
};

export const useComboCounter = () => {
  const dispatch = useDispatch();

  const updateCounter = useCallback(
    (counter) => dispatch(GameActions.requestSetComboCounter(counter)),
    [dispatch],
  );

  const comboCounter = useSelector(GameSelectors.getComboCounter);

  return {
    comboCounter,
    updateCounter,
  };
};

export const useResults = () => {
  const dispatch = useDispatch();

  const getResults = useCallback(
    () => dispatch(GameActions.requestGetResults()),
    [dispatch],
  );

  const results = useSelector(GameSelectors.getResultsConfig);

  return {
    results,
    getResults,
  };
};
