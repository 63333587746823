import { IonApp, setupIonicReact } from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/global.scss";
import "./theme/variables.scss";

// Pages
import Router from "../src/routes/Router";

// Other
import I18n from "redux-i18n";
import { store } from "./redux";
import { Provider } from "react-redux";

setupIonicReact({
  swipeBackEnabled: false,
  hardwareBackButton: false,
});

window.devicePixelRatio = 2;

const App: React.FC = () => (
  <IonApp>
    <Provider store={store}>
      <I18n translations={{}} useReducer>
        <Router />
      </I18n>
    </Provider>
  </IonApp>
);

export default App;
