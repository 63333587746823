import React, { useMemo, useCallback } from "react";
import { useIonRouter } from "@ionic/react";
import { useGame } from "../../redux/game/hooks";
import { useLocalize } from "../../redux/translation/localize";

import Loader from "../../atoms/Loader/Loader";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import Backgrounds from "../../components/Backgrounds/Backgrounds";

import "./Question.scss";

const Question: React.FC = () => {
  const t = useLocalize();
  const { push } = useIonRouter();
  const { questionIndex, questions, gameLength } = useGame();
  const currentQuestion = useMemo(
    () => questions[questionIndex],
    [questionIndex, questions],
  );
  const pagination = `${t("question")} ${questionIndex + 1}/${gameLength}`;
  const fontStyle = useMemo(
    () => ({
      color: currentQuestion?.image_type === "background" ? "white" : "black",
      margin:
        currentQuestion?.image_type === "background" ||
        !currentQuestion?.image_type
          ? "1.25rem 0"
          : "0",
    }),
    [currentQuestion?.image_type],
  );
  const backgroundStyle = useMemo(
    () => ({
      backgroundColor:
        currentQuestion?.image_type === "background" ? "" : "white",
    }),
    [currentQuestion?.image_type],
  );

  const play = useCallback(() => {
    const elem = document.getElementById("questionPage");
    if (!elem) return;
    elem.style.opacity = "0";
    setTimeout(() => push(`/game/${questionIndex + 1}`), 1000);
  }, [push, questionIndex]);

  return (
    <div id="questionPage" className="page" style={backgroundStyle}>
      <Header question />
      {!currentQuestion && <Loader />}
      {!!currentQuestion && currentQuestion?.image_type === "background" && (
        <Backgrounds
          blur
          color="black"
          instructions
          url={currentQuestion?.image}
        />
      )}
      {!!currentQuestion && (
        <div className="questionBody">
          {currentQuestion?.image_type === "cover" && (
            <img
              className="coverImg"
              alt="question"
              src={currentQuestion?.image}
            />
          )}
          <div className="pagination" style={fontStyle}>
            {pagination}
          </div>
          <div className="questionTitle" style={fontStyle}>
            {currentQuestion?.text}
          </div>
          <Button className="playButton" label={t("start")} onClick={play} />
        </div>
      )}
    </div>
  );
};

export default Question;
