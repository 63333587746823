import React, { MouseEventHandler } from "react";

// Style && Utils
import "./Button.scss";

const Button: React.FC<{
  label?: string;
  onClick?: MouseEventHandler;
  className?: string;
}> = ({ label, onClick, className }) => (
  <div className={className || "button"} onClick={onClick}>
    {label}
  </div>
);

export default Button;
