export const addGnl = (src: string, targetLength: number) => {
  const lines: string[] = [];
  let ret: string = src;

  const extractFirstLine = (src: string) => {
    let spaceFound = false;
    let length = targetLength;
    if (src.split(" ").length === 1) {
      lines.push(src);
      return "";
    }
    while (!spaceFound) {
      const str = src.substr(0, length);
      const lastChar = str.substr(-1);
      if (lastChar === " ") {
        spaceFound = true;
        lines.push(str);
      } else if (str.split(" ").length === 1) {
        length++;
      } else {
        length--;
      }
    }
    return src.substr(length);
  };

  while (ret.length) {
    ret = extractFirstLine(ret);
  }
  return lines;
};

export const getLongestString = (arr: string[]) => {
  let length = 0;
  let longest;

  for (var i = 0; i < arr.length; i++) {
    if (arr[i].length > length) {
      length = arr[i].length;
      longest = arr[i];
    }
  }
  return longest;
};
