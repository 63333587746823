import { all, fork, call } from "redux-saga/effects";
import initSaga from "./initSaga";

// Sagas
import AppSagas from "./app/sagas";
import GameSagas from "./game/sagas";
import TranslationSagas from "./translation/sagas";

function* loop() {
  yield all([AppSagas.loop(), GameSagas.loop(), TranslationSagas.loop()]);
  return null;
}

export default function* rootSaga() {
  yield fork(loop);
  yield call(initSaga);
}
