import React from "react";

import Icon from "../../../../atoms/Icon/Icon";

import "./InstructionsSlides.scss";
import icons from "../../../../types/IconTypes";

interface SlideProps {
  image: string;
  instructions: string;
  index: number;
  length: number;
  next: () => void;
  prev: () => void;
}

const InstructionsSlides: React.FC<SlideProps> = ({
  image,
  instructions,
  index,
  length,
  next,
  prev,
}) => {
  return (
    <div id="instructionsSlideContent">
      <div className="slideImg">
        {index !== 0 && (
          <Icon
            className="chevronIconLeft"
            icon={icons.CHEVRON_LEFT}
            onClick={prev}
          />
        )}
        <img className="fruitVisual" src={image} alt="fruit to slice" />
        {index !== length - 1 && (
          <Icon
            className="chevronIconRight"
            icon={icons.CHEVRON_RIGHT}
            onClick={next}
          />
        )}
      </div>
      <div className="instructions">{instructions}</div>
    </div>
  );
};

export default InstructionsSlides;
