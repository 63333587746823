import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIonRouter } from "@ionic/react";
import { useLocalize } from "../../redux/translation/localize";
import {
  useUpdateGame,
  useGame,
  useResults,
  useComboCounter,
  useCurrentUser,
  useGameConfig,
} from "../../redux/game/hooks";

// Components
import Confetti from "react-dom-confetti";
import Loader from "../../atoms/Loader/Loader";
import Icon from "../../atoms/Icon/Icon";
import Ranking from "./partials/Ranking/Ranking";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";

// Style && Utils
import "./EndPage.scss";
import icons from "../../types/IconTypes";
import { defaultProfile } from "../../utils/defaultProfile";

// Constants
const ConfettiConfig = {
  angle: 90,
  spread: 50,
  startVelocity: window.innerHeight / 13,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 2500,
  stagger: 5,
  width: "10px",
  height: "10px",
  colors: [
    "rgb(255, 203, 73)",
    "rgb(0, 200, 157)",
    "rgb(127, 101, 192)",
    "rgb(236, 65, 48)",
    "rgb(127, 101, 192)",
  ],
};

const EndPage: React.FC<any> = (props) => {
  const t = useLocalize();
  const { push } = useIonRouter();
  const { results } = useResults();
  const { getConfig } = useGameConfig();
  const { currentUser } = useCurrentUser();
  const { updateCounter } = useComboCounter();
  const { currentScore } = useGame();
  const { setCurrentScore, setQuestionIndex } = useUpdateGame();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sendConfetti, setSendConffeti] = useState<boolean>(false);

  const termination: any = useMemo(() => {
    const term = results?.userScore?.rank?.toString().slice(-1);
    if (term === "1") return t("ranking_st");
    if (term === "2") return t("ranking_nd");
    if (term === "3") return t("ranking_rd");
    return t("ranking_th");
  }, [results?.userScore?.rank, t]);
  const rank = results?.userScore?.rank
    ? results?.userScore?.rank?.toString() + termination
    : "";
  const highestScore: string =
    results?.userScore?.highestScore.toString() || "0";
  const congratulations = useMemo(() => {
    if (currentScore > currentUser?.highestScore) {
      if (results?.userScore?.rank === 1)
        return [
          t("ninja_final_4"),
          t("ninja_final_4_bis"),
          t("ninja_final_4_ter"),
        ];
      if (
        results?.userScore?.rank <= 3 &&
        (currentUser?.rank > 3 || !currentUser?.rank)
      )
        return [
          t("ninja_final_3"),
          t("ninja_final_3_bis"),
          t("ninja_final_3_ter"),
        ];
      if (!currentUser?.rank)
        return [
          t("ninja_final_1"),
          t("ninja_final_1_bis"),
          t("ninja_final_1_ter"),
        ];
      else return [t("ninja_final_2"), t("ninja_final_2_bis")];
    } else {
      return [
        t("ninja_final_1"),
        t("ninja_final_1_bis"),
        t("ninja_final_1_ter"),
      ];
    }
  }, [
    currentScore,
    currentUser?.highestScore,
    currentUser?.rank,
    results?.userScore?.rank,
    t,
  ]);

  useEffect(() => {
    setSendConffeti(true);
    setIsOpen(true);
  }, []);

  const play = useCallback(() => {
    getConfig();
    setIsOpen(false);
    setQuestionIndex(0);
    setCurrentScore(0);
    updateCounter(1);
    const elem = document.getElementById("EndPage");
    if (!elem) return;
    elem.style.opacity = "0";
    setTimeout(() => push(`/question/${1}`), 1000);
  }, [getConfig, push, setCurrentScore, setQuestionIndex, updateCounter]);

  return (
    <div id="EndPage" className="page">
      <Header />
      {!results && <Loader />}
      {!!results && (
        <div className="endPageBody">
          <div className="endPageContent">
            <div className="profilePicture">
              <Icon className="blason" icon={icons.BLASON} />
              <img
                className="picture"
                src={currentUser?.pic ? currentUser.pic : defaultProfile}
                alt="current user"
              />
            </div>
            <div className="endTitle">{t("ninja_success_title")}</div>
            <div className="endScore">
              {t("ninja_final_score", {
                score: currentScore.toString(),
                s: currentScore > 1 ? "s" : "",
              })}
            </div>
            <div className="endScore">
              {t("ninja_final_highestscore", { highestScore })}
            </div>
            <div className="endScore">{t("ninja_final_rank", { rank })}</div>
            <div className="endRanking">
              {congratulations.map((wording: string, index: number) => (
                <div key={index}>{wording}</div>
              ))}
            </div>
            <Button label={t("replay")} onClick={play} />
          </div>
          <Confetti active={sendConfetti} config={ConfettiConfig} />
          <Ranking isOpen={isOpen} users={results?.leaderBoard || []} />
        </div>
      )}
    </div>
  );
};

export default EndPage;
